import React from 'react';
import { useAppSelector } from '~/Store/hooks';
import { OrganizationSelectors } from '~/Store/Organization';
import { BodyInfo } from './Body';
import { EditForm } from './Edit';

export const Info = () => {
  const isEditMode = useAppSelector(OrganizationSelectors.getIsEditMode);

  return (
    <div>
      {isEditMode ? <EditForm /> : <BodyInfo />}
    </div>
  );
};
