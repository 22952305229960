import { useSelector } from 'react-redux';
import { UserSelectors } from '~/Store/User';
import { OrgClaim, RootClaim } from '~/Views/Main/Routes';

export default function useHasPermissions(allowedRoles: Array<RootClaim | OrgClaim>) {
  const userRoles = useSelector(UserSelectors.getRoles);
  const orgRoles = useSelector(UserSelectors.getOrgRoles);
  const roles = allowedRoles.filter(r => userRoles.includes(r) || orgRoles.includes(r));
  return roles.length > 0 ? true: false;
}
