import React, { FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserSelectors } from '~/Store/User';
import { OrgClaim, RootClaim } from '~/Views/Main/Routes';
import { NotAllowed } from './NotAllowed';

interface PrivateRouteProps extends RouteProps {
  allowedRoles: Array<RootClaim | OrgClaim>;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ children, allowedRoles, ...rest }) => {
  const userRoles = useSelector(UserSelectors.getRoles);
  const orgRoles = useSelector(UserSelectors.getOrgRoles);
  const roles = allowedRoles.filter((r) => userRoles.includes(r) || orgRoles.includes(r));
  return (
    <Route
      {...rest}
      render={() => (roles.length > 0 ? (children) : <NotAllowed />)}

    />
  );
};
