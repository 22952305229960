import React, { FC, useEffect } from 'react';
import { Layout, LayoutProps } from 'antd';

import { useAppDispatch } from '~/Store/hooks';
import { LayoutActions } from '~/Store/Layout';

import styles from './Page.module.less';

const { Content } = Layout;

interface PageProps {
  title?: string;
}

export const Page: FC<LayoutProps & PageProps> = ({
  children,
  title,
  className,
  ...props
}) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (title) {
      dispatch(LayoutActions.setDocumentTitle(title));
    }
  }, []);

  return (
    <Layout className={`${styles.Page} ${className}`} {...props}>
      <Content className={styles.Content}>
        {children}
      </Content>
    </Layout>
  );
};
