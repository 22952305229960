import { createSlice } from '@reduxjs/toolkit';

export interface LayoutStore {
  LeftSider: {
    collapsed: boolean
  },
  Document: {
    title: string
  }
}

const initialState: LayoutStore = {
  LeftSider: { collapsed: false },
  Document: { title: 'Hicas' },
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleLeftSider: (state) => { state.LeftSider.collapsed = !state.LeftSider.collapsed; },
    setDocumentTitle: (state, action) => { state.Document.title = action.payload; },
  },
});

export const LayoutActions = layoutSlice.actions;
export const LayoutReducer = layoutSlice.reducer;
