import React, { FC, useEffect } from 'react';
import { Spin, SpinProps } from 'antd';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '~/Store/hooks';
import { WaitOverlayActions, WaitOverlaySelectors } from '~/Store/Overlays';

interface WaitOverlayProps extends SpinProps{
  name: string;
}

export const WaitOverlay:FC<WaitOverlayProps> = ({ children, name, ...rest }) => {
  const dispatch = useAppDispatch();
  const visible = useSelector(WaitOverlaySelectors.getWaitOverlayVisible(name));
  useEffect(() => {
    dispatch(WaitOverlayActions.initialize(name));
  }, []);
  return (
    <Spin spinning={visible} {...rest}>
      {children}
    </Spin>
  );
};
