import React from 'react';
import { useSelector } from 'react-redux';
import { PageHeader as AntdPageHeader } from 'antd';
import { UserLogin } from './UserLogin';
import { LayoutSelectors } from '~/Store/Layout';
import style from './PageHeader.module.less';

export const PageHeader = () => {
  const title = useSelector(LayoutSelectors.getDocumentTitle);

  return (
    <AntdPageHeader
      title={title}
      className={style.PageHeader}
      extra={<UserLogin />}
    />
  );
};
