/* eslint-disable @typescript-eslint/indent */
import { SaveOutlined, UserOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Row,
  Typography,
} from 'antd';
import React from 'react';
import { WaitOverlay } from '~/Components';
import { OrganizationsService, UpdateInfoParams } from '~/Services/OrganizationService';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { OrganizationActions } from '~/Store/Organization';
import { WaitOverlayActions } from '~/Store/Overlays';
import { UserSelectors } from '~/Store/User';
import styles from './Info.module.less';

const { Title } = Typography;

const waitOverlayName = 'fetchingOrgInfo';

export const EditForm = () => {
  const dispatch = useAppDispatch();
  const organization = useAppSelector(UserSelectors.getDefaultOrganization);
  const currentUser = useAppSelector(UserSelectors.getCurrentUser);

  const {
    companyName, billingAddress, billingAddress2,
    billingCity, billingState, billingZip, guid,
  } = organization || {};
  const {
    email,
  } = currentUser || {};

  const onFinish = (value: UpdateInfoParams) => {
    dispatch(OrganizationActions.toggleIsEditMode());
    try {
      dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: true }));
      const params: Partial<UpdateInfoParams> = {
        companyName: value.companyName,
        billingAddress: value.billingAddress,
        billingAddress2: value.billingAddress2,
        billingCity: value.billingCity,
        billingState: value.billingState,
        billingZip: value.billingState,
        guid,
      };
      OrganizationsService.Put.updateOrganizationInfo(guid, params);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: false }));
    }
  };

  const cancelChanges = () => {
    dispatch(OrganizationActions.toggleIsEditMode());
  };

  return (
    <WaitOverlay
      name={waitOverlayName}
      size="large"
      wrapperClassName={styles.FullOverlay}
    >
      <div>
        <Form
          layout="vertical"
          initialValues={{
            companyName,
            email,
            role: 'Job title',
            billingAddress,
            billingAddress2,
            billingCity,
            billingState,
            billingZip,
          }}
          onFinish={onFinish}
        >
          <Row align="stretch" className={styles.editRow}>
            <Title style={{ flex: 1 }} level={4}>Organization Information</Title>
            <div className={styles.CancelButton}>
              <Button
                onClick={cancelChanges}
                danger
              >
                Cancel
              </Button>
            </div>
            <Button
              className={styles.editOrg}
              htmlType="submit"
              type="primary"
            >
              Save Changes
              <SaveOutlined />
            </Button>
          </Row>
          <div className={styles.organizationInfo}>
            <Row className={styles.editRow}>
              <Avatar
                shape="square"
                size="large"
                icon={<UserOutlined />}
                className={styles.orgLogo}
              />
              <div
                className={styles.editOrg}
              >
                <div className={styles.editWord}>
                  Change avatar
                </div>
              </div>
            </Row>
            <Form.Item label="Organization Name" name="companyName">
              <Input width="100%" />
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Input width="100%" />
            </Form.Item>
            <Form.Item label="Office address" name="billingAddress">
              <Input width="100%" />
            </Form.Item>
            <Form.Item label="Billing address" name="billingAddress2">
              <Input width="100%" />
            </Form.Item>
            <Row className={styles.editRow} gutter={[16, 0]}>
              <Col span={8}>
                <Form.Item label="City" name="billingCity">
                  <Input width="100%" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="State" name="billingState">
                  <Input width="100%" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Zip" name="billingZip">
                  <Input width="100%" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </WaitOverlay>
  );
};
