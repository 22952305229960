import {
  Avatar,
  Button,
  Col,
  message,
  notification,
  Row,
  Tag,
} from 'antd';
import React, { FC, useState } from 'react';
import { UserService } from '~/Services';
import { Utils } from '~/Utils';
import { tagColor } from '../../Organizations/Users/UserItem';
import { OrganizationItemData } from '../Info/OrganizationItem';
import styles from './Invitation.module.less';

interface InvitationItemProps {
  org: OrganizationItemData,
  refreshOrganizations: () => Promise<void>,
}

export const InvitationItem: FC<InvitationItemProps> = ({ org, refreshOrganizations }) => {
  const { guid, invitationStatus } = org;
  const [confirming, setConfirming] = useState('');

  const responseInvitation = async (accept: boolean) => {
    try {
      setConfirming(accept ? 'Accept': 'Decline');
      await UserService.Put.confirmInvitation(guid, accept);
      await refreshOrganizations();
    } catch (error: any) {
      notification.error({
        message: 'Oops!',
        description: message
      });
    } finally {
      setConfirming('');
    }
  };
  return (
    <Col
      xl={12}
      xs={24}
    >
      <Row align="middle" justify="space-between" className={styles.OrgRow}>
        <Row>
          <Col className={styles.OrganizationItemAvatar}>
            <Avatar
            shape='square'
              // shape={org.avatarUrl ? 'square' : 'circle'}
              size={50}
              src={org.avatarUrl}
              style={{
                backgroundColor: org.avatarUrl
                  ? undefined
                  : Utils.stringToColour(org.guid),
              }}
            >
              {org.companyName.charAt(0).toLocaleUpperCase()}
            </Avatar>
          </Col>
          <Col>
            <Row>
              <strong>{org.companyName}</strong>
            </Row>
            <Row>
              {org.billingAddress}
            </Row>
          </Col>
        </Row>
        <Row
          className={styles.OrgAcpBtn}
        >
          {!invitationStatus || invitationStatus === 'Pending' ? (
            <Row>
              <Button
                type="text"
                shape="round"
                loading={confirming === 'Decline'}
                onClick={() => responseInvitation(false)}
              >
                Decline
              </Button>
              <Button
                type="primary"
                shape="round"
                loading={confirming === 'Accept'}
                onClick={() => responseInvitation(true)}
              >
                Accept
              </Button>
            </Row>
          )
            : (
              <Row>
                <Tag color={tagColor(invitationStatus)}>
                  {invitationStatus}
                </Tag>
              </Row>
            )}
        </Row>
      </Row>
    </Col>
  );
};
