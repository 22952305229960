import { Col, ColProps, List } from 'antd';
import React, { FC } from 'react';
import { LicenseAvatar } from '../LicenseAvatar';
import styles from './LicenseCards.module.less';

interface LicenseCardsProps {
  license: any;
  col?: ColProps
}

export const LicenseCards: FC<LicenseCardsProps> = ({ license, col }) => {
  const { guid, licenseType, expiredate } = license;

  const dateLocaleString = (date: string) => {
    const d = new Date(date);
    return d.toLocaleDateString();
  };

  return (
    <Col key={guid} {...col}>
      <div className={styles.License}>
        <List.Item.Meta
          avatar={<LicenseAvatar type={licenseType.type} width={50} height={50} />}
          title={<strong>{licenseType.name}</strong>}
          description={`Exp: ${dateLocaleString(expiredate)}`}
        />
      </div>
    </Col>
  );
};
