import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { UserSelectors } from '~/Store/User';

export const OrganizationOverlay: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const authenticatedWithOrg = useSelector(
    UserSelectors.isAuthenticatedWithOrg
  );

  return <>{authenticatedWithOrg && children}</>;
};
