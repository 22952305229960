import { useHistory } from 'react-router-dom';
import { Avatar, Button, Popover } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import { useAppDispatch } from '~/Store/hooks';

import { UserSelectors, UserActions } from '../../Store/User';
import { UserService } from '~/Services';

import styles from './UserLogin.module.less';
import { UserMenu } from './UserMenu';
import { Utils } from '~/Utils';
import { getEmail, getUserId } from '~/Store/User/selectors';

const { setAvatarUrl } = UserActions;
const { isAuthenticated, getFirstInitLastName, getAvatarUrl } = UserSelectors;

export const UserLogin = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [visible, setLoginVisibility] = useState(false);
  const pushToLogin = () => {
    history.push('/login');
  };
  const authenticated = useSelector(isAuthenticated);
  const userId = useSelector(getUserId);
  const email = useSelector(getEmail);
  const displayName = useSelector(getFirstInitLastName);
  const avatarUrl = useSelector(getAvatarUrl);

  const getAvatar = async () => {
    try {
      const res: AxiosResponse<any> =
        await UserService.Get.fetchCurrentUserAvatar();

      const { data } = res;
      if (data.result) {
        dispatch(setAvatarUrl(data.result.avatarUrl));
      }
    } catch (err) {
      // TODO: open toaster
    }
  };

  useEffect(() => {
    if (authenticated) {
      getAvatar();
    }
  }, [authenticated]);

  const closeUserMenu = () => {
    setLoginVisibility(false);
  };

  return (
    <>
      {!authenticated ? (
        <p
          className={styles.UserLogin}
          onClick={pushToLogin}
        >
          <Avatar
            icon={<UserOutlined />}
            style={{
              backgroundColor: Utils.stringToColour(),
              marginRight: '8px',
            }}
          />
          Login
        </p>
      ) : (
        <Popover
          placement="bottomRight"
          content={<UserMenu closeSelf={closeUserMenu} />}
          trigger="click"
          visible={visible}
          onVisibleChange={() => setLoginVisibility(!visible)}
        >
          <p className={styles.UserLogin}>
            <Avatar
              src={avatarUrl}
              alt={displayName}
              style={{
                backgroundColor:
                  !avatarUrl || avatarUrl === 'https://'
                    ? Utils.stringToColour(userId)
                    : undefined,
                marginRight: '8px',
              }}
            >
              {displayName.charAt(0).toLocaleUpperCase() ||
                email.charAt(0).toLocaleUpperCase()}
            </Avatar>
            {displayName}
          </p>
        </Popover>
      )}
    </>
  );
};
