import React, { FC, SVGProps } from 'react';

export const FilterSvg: FC<SVGProps<any>> = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    width={width || "32"}
    height={height || "32"}
    viewBox="0 0 256 256"
    xmlSpace="preserve"
  >
    <g transform="translate(128 128) scale(0.72 0.72)">
      <g
        style={{stroke: 'none', strokeWidth: 0, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'none', fillRule: 'nonzero', opacity: 1}}
        transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)"
      >
        <path
          d="M 87.632 28.952 H 2.368 C 1.06 28.952 0 27.892 0 26.584 V 19.32 c 0 -1.308 1.06 -2.368 2.368 -2.368 h 85.263 c 1.308 0 2.368 1.06 2.368 2.368 v 7.263 C 90 27.892 88.94 28.952 87.632 28.952 z"
          style={{stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(0,0,0)', fillRule: 'nonzero', opacity: 1}}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 76.918 51 H 13.083 c -1.308 0 -2.368 -1.06 -2.368 -2.368 v -7.263 c 0 -1.308 1.06 -2.368 2.368 -2.368 h 63.835 c 1.308 0 2.368 1.06 2.368 2.368 v 7.263 C 79.286 49.94 78.226 51 76.918 51 z"
          style={{stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(0,0,0)', fillRule: 'nonzero', opacity: 1}}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 60.846 73.048 H 29.154 c -1.308 0 -2.368 -1.06 -2.368 -2.368 v -7.263 c 0 -1.308 1.06 -2.368 2.368 -2.368 h 31.692 c 1.308 0 2.368 1.06 2.368 2.368 v 7.263 C 63.214 71.988 62.154 73.048 60.846 73.048 z"
          style={{stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(0,0,0)', fillRule: 'nonzero', opacity: 1}}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
      </g>
    </g>
  </svg>
);
