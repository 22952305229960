import { OrgClaim, RootClaim } from "~/Views/Main/Routes";

class Utils {
  public static delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  public static encodeRFC5987ValueChars = (str:  string) => {
    return encodeURIComponent(str).
      // Note that although RFC3986 reserves "!", RFC5987 does not,
      // so we do not need to escape it
      replace(/['()*]/g, c => "%" + c.charCodeAt(0).toString(16)). 
      // i.e., %27 %28 %29 %2a (Note that valid encoding of "*" is %2A
      // which necessitates calling toUpperCase() to properly encode)
      // The following are not required for percent-encoding per RFC5987,
      // so we can allow for a little better readability over the wire: |`^
      replace(/%(7C|60|5E)/g, (str, hex) => String.fromCharCode(parseInt(hex, 16)));
  }

  public static stringToColour = (str = 'defaultColour') => {
    if (!str) str = 'defaultColour';
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = (hash & 0x00ffffff).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - colour.length) + colour;
  };
}

export default Utils;