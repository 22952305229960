import React, { FC } from 'react';
import { Menu } from 'antd';
import {
  Switch, useLocation, Route, useHistory, Redirect, useRouteMatch,
} from 'react-router-dom';
import { Page } from '~/Components';
import { ProfileInfo } from '~/Views/Main/Profile/Info';

import styles from './Profile.module.less';
import { Invitation } from './Invitation';

export const Profile:FC<any> = () => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const navigate = (path:string) => {
    history.push(`${match.path}${path}`);
  };

  const paths = [
    {
      key: 'info',
      path: '',
      title: 'Info',
      component: ProfileInfo,
    },
    {
      key: 'inv',
      path: '/invitation',
      title: 'Invitation',
      component: Invitation,
    },
  ];

  const activeKeys = ():string => {
    const currentTab = paths.find((p) => `${match.path}${p.path}` === location.pathname);
    return currentTab ? currentTab.key : 'info';
  };

  return (
    <>
      <Menu mode="horizontal" selectedKeys={[activeKeys()]} style={{ background: '#FFF' }}>
        {paths.map(({ key, path, title }) => (
          <Menu.Item key={key} onClick={() => navigate(path)}>{title}</Menu.Item>
        ))}

      </Menu>
      <Page title="Profile" className={styles.Profile}>
        <Switch>
          {paths.map(({ key, path, component: Component }) => (
            <Route key={key} exact path={`${match.path}${path}`} component={Component} />
          ))}

          <Redirect to="/profile" />
        </Switch>
      </Page>
    </>
  );
};
