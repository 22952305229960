import { MoreOutlined, CaretRightOutlined } from '@ant-design/icons';
import {
  Avatar,
  Collapse,
  Dropdown,
  List,
  Menu,
  Row,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { WaitOverlay } from '~/Components';
import { LicenseAvatar } from '~/Components/LicenseAvatar';
import { ScrollBar } from '~/Components/ScrollBar';
import { OrganizationsService } from '~/Services/OrganizationService';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  OrganizationActions,
  OrganizationSelectors,
} from '~/Store/Organization';
import { WaitOverlayActions } from '~/Store/Overlays';
import { UserSelectors } from '~/Store/User';
import { Utils } from '~/Utils';
import { AddUser } from './AddUser';

import styles from './Licenses.module.less';

const { Title } = Typography;
const { Panel } = Collapse;

export const Licenses = () => {
  const dispatch = useAppDispatch();
  const organization = useAppSelector(UserSelectors.getDefaultOrganization);
  const orgLicenses = useAppSelector(OrganizationSelectors.getOrgLicense);
  const [activeLicense, setActiveLicense] = useState<
  string[] | string | undefined
  >();

  const { guid } = organization || {};

  const waitOverlay = 'loadingOrgLicenses';

  const fetchOrgLicense = async () => {
    try {
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlay, value: true }),
      );
      const req: any = await OrganizationsService.Get.getOrganizationLicense(guid);
      const { data } = req;
      dispatch(OrganizationActions.setOrgLicense(data));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlay, value: false }),
      );
    }
  };
  useEffect(() => {
    fetchOrgLicense();
  }, []);

  useEffect(() => {
    setActiveLicense(orgLicenses.length > 0 ? orgLicenses[0].guid : undefined);
  }, [orgLicenses.length]);

  const RemoveOrRevokeUserLicense = async (
    evt: any,
    orgId: string,
    licenseId: string,
    userId: string,
  ) => {
    try {
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlay, value: true }),
      );
      const { key } = evt;
      if (key === 'remove') {
        await OrganizationsService.Delete.removeUsersFromOrgLicense(
          orgId,
          userId,
          [licenseId],
        );
      }
      if (key === 'revoke') {
        // todo
      }
      await fetchOrgLicense();
    } catch (error) {
      // todo
    } finally {
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlay, value: false }),
      );
    }
  };

  const menu = (orgId: string, licenseId: string, userId: string) => (
    <Menu
      onClick={(evt) => RemoveOrRevokeUserLicense(evt, orgId, licenseId, userId)}
    >
      <Menu.Item key="remove">{'Remove user\'s license'}</Menu.Item>
      {/* <Menu.Item key="revoke">{'Revoke user\'s license'}</Menu.Item> */}
    </Menu>
  );

  const activeLicenseCallback = (key: string[] | string | undefined) => {
    setActiveLicense(key);
  };

  return (
    <WaitOverlay name={waitOverlay} size="large">
      <Row>
        <Title style={{ flex: 1 }} level={4}>
          My Licenses
        </Title>
      </Row>
      <div className={styles.licenseList}>
        <ScrollBar>
          <Collapse
            accordion
            expandIconPosition="right"
            activeKey={activeLicense}
            onChange={activeLicenseCallback}
            // eslint-disable-next-line react/no-unstable-nested-components
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            ghost
            className={styles.licenseCollapse}
          >
            {orgLicenses.map((lic) => (
              <Panel
                className={styles.licenseCollapsePanel}
                header={(
                  <div style={{ display: 'flex', width: '80%' }}>
                    <List.Item.Meta
                      avatar={(
                        <LicenseAvatar
                          type={lic.licenseType.type}
                          height={50}
                          width={50}
                        />
                      )}
                      title={<strong>{lic.licenseType.name}</strong>}
                      description={`${
                        lic.user.filter((i) => i.invitationStatus === 'Accepted')
                          .length
                      } out of ${lic.user.length} used`}
                    />
                  </div>
                )}
                key={lic.guid}
                extra={(
                  <AddUser
                    licenseId={lic.guid}
                    refetchOrgLicenses={fetchOrgLicense}
                    openLicensePanel={activeLicenseCallback}
                  />
                )}
              >
                {lic.user.map((item) => (
                  <div key={item.id} className={styles.panelContent}>
                    <div>
                      <i className={styles.addIcon}>
                        <Avatar
                          src={item.avatarUrl && !item.avatarUrl.startsWith('http') ? `https://${item.avatarUrl}` : undefined}
                          style={{
                            backgroundColor: item.avatarUrl
                              ? undefined
                              : Utils.stringToColour(item.id),
                          }}
                        >
                          {(item.firstname || item.email)
                            .charAt(0)
                            .toLocaleUpperCase()}
                        </Avatar>
                      </i>
                      {`${item.firstname} ${item.lastname}`}
                    </div>
                    <Dropdown
                      overlay={menu(lic.organizationId, lic.guid, item.id)}
                      trigger={['click']}
                    >
                      <MoreOutlined className={styles.addUserIcon} />
                    </Dropdown>
                  </div>
                ))}
              </Panel>
            ))}
          </Collapse>
        </ScrollBar>
      </div>
    </WaitOverlay>
  );
};
