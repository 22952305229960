import { Delete } from './Delete';
import { Get } from './Get';
import { Post } from './Post';
import { Put } from './Put';

export interface UpdateInfoParams {
  companyName: string,
  billingAddress: string,
  billingAddress2: string,
  billingCity: string,
  billingState: string,
  billingZip: string,
  crmId: string,
  duns: string,
  naics: string,
  guid?: string

}
interface UpdateRoleParams {
  roleName: string,
  newRoleName: string,
}
interface UserFilter {
  filter?: string,
  expand?: string,
  paging?: boolean,
  orderBy?: string,
  page?: number,
  pageSize?: number,
  offset?: number,
  take?: number,
  invitationStatus?: string,
  roles?: string
}

class OrganizationServiceController {
  private licenseManagerUrl = process.env.LICENSE_MANAGER_URL ?? (window.location.protocol + "//" + window.location.hostname + "/license_manager");

  private organizationServerUrl = `${this.licenseManagerUrl}/organizations`;

  public Put = {
    updateOrganizationsInfo: (data: UpdateInfoParams) => Put(this.organizationServerUrl, data),
    updateOrganizationInfo: (id: string, data: Partial<UpdateInfoParams>) => {
      const url = `${this.organizationServerUrl}/${id}`;
      return Put(url, data);
    },
    updateOrganizationRole: (id: string, data: UpdateRoleParams) => {
      const url = `${this.organizationServerUrl}/${id}/organizationRoles`;
      return Put(url, data);
    },
  };

  public Get = {
    getOrganizationUsers: (orgId: string, data?: UserFilter, roles?: string[], invitationStatus?: string[]) => {
      let url = `${this.organizationServerUrl}/${orgId}/users`;

      let rolesParam = '';
      if (roles) {
        rolesParam = `roles=${roles.join('&roles=')}`;
      }
      let statusParam = '';
      if (invitationStatus) {
        statusParam = `invitationStatus=${invitationStatus.join('&invitationStatus=')}`;
      }
      if (rolesParam || statusParam) {
        url += `?${[rolesParam, statusParam].filter(i => i).join('&')}`;
      }
      
      const filterData = {
        params: {
          ...data,
        },
      };
      return Get(url, filterData);
    },
    searchOrganizationUsers: (data: UserFilter) => {
      const filterData = {
        params: {
          ...data,
        },
      };
      return Get(this.organizationServerUrl, filterData);
    },
    getOrganization: (orgId: string) => {
      const url = `${this.organizationServerUrl}/${orgId}`;
      return Get(url);
    },
    getOrganizationLicense: (orgId: string) => {
      const url = `${this.organizationServerUrl}/${orgId}/licenses`;
      return Get(url);
    },
    getOrganizationRoles: (orgId: string) => {
      const url = `${this.organizationServerUrl}/${orgId}/organizationRoles`;
      return Get(url);
    },
    getOrganizationAdmin: (orgId: string) => {
      const url = `${this.organizationServerUrl}/${orgId}/administrators`;
      return Get(url);
    },
  };

  public Post = {
    addOrganizationAdmin: (orgId: string, adminId: string) => {
      const url = `${this.organizationServerUrl}/${orgId}/administrators`;
      return Post(url, adminId);
    },
    addOrganizationUser: (orgId: string, userId: string) => {
      const url = `${this.organizationServerUrl}/${orgId}/users`;
      return Post(url, userId);
    },
    createOrganizations: (data: UpdateInfoParams) => Post(this.organizationServerUrl, data),
    createOrganizationRole: (orgId: string, newRole: string) => {
      const url = `${this.organizationServerUrl}/${orgId}/organizationRoles`;
      return Post(url, newRole);
    },
    changeUserStatus: (orgId: string, data: { email: string, status: string }[]) => (
      Post(`${this.organizationServerUrl}/${orgId}/notify/userinvites`, data)
    ),
    addOrgLicenseToUsers: (orgId: string, userId: string, licenseIds: string[]) => (
      Post(`${this.organizationServerUrl}/${orgId}/users/${userId}/licenses`, licenseIds)
    ),
  };

  public Delete = {
    removeUsers: (orgId: string, userIds: string[]) => {
      const url = `${this.organizationServerUrl}/${orgId}/users`;
      return Delete(url, userIds);
    },
    removeUsersFromOrgLicense: (orgId: string, userId: string, licenseIds: string[]) => (
      Delete(`${this.organizationServerUrl}/${orgId}/users/${userId}/licenses`, licenseIds)
    ),
  };
}

export const OrganizationsService = new OrganizationServiceController();
