import { RootState } from '~/Store/hooks';

export const isAuthenticated = (state: RootState) => state.user.authenticated;
export const isAuthenticatedWithOrg = (state: RootState) => state.user.authWithOrg;
export const getToken = (state: RootState) => state.user.token;
export const getRefreshToken = (state: RootState) => state.user.refreshToken;
export const getFirstName = (state: RootState) => state.user.firstName;
export const getLastName = (state: RootState) => state.user.lastName;
export const getRoles = (state: RootState) => state.user.roles;
export const getOrgRoles = (state: RootState) => state.user.orgRoles;
export const getEmail = (state: RootState) => state.user.email;
export const getCurrentUser = (state: RootState) => state.user.current;
export const getUserOrganizations = (state:RootState) => state.user.organizations;
export const getUserPreferences = (state: RootState) => state.user.preferences;
export const getDefaultOrganization = (state: RootState) => state.user.defaultOrganization;
export const getUserLicenses = (state:RootState) => state.user.licenses;
export const getFirstInitLastName = (state: RootState) => {
  const fName = state.user.firstName;
  const lName = state.user.lastName;
  if (!fName && !lName) { return state.user.userName; }
  return fName[0].toUpperCase() + lName[0].toUpperCase() + lName.substr(1).toLowerCase();
};
export const getUserId = (state: RootState) => state.user.id;
export const getAvatarUrl = (state: RootState) => `https://${state.user.avatarUrl}`;
