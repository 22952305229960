import qs from 'qs';
import axios, {
  AxiosError, AxiosPromise, AxiosRequestConfig, AxiosResponse,
} from 'axios';
import merge from 'lodash.merge';
import { Get } from './Get';
import { Put } from './Put';
import { Post } from './Post';
// import {env} from '../Application';
import { UserPreferencesData, RegisterUserData } from './UserService.d';
import { store } from '~/Store';
import { UserSelectors } from '~/Store/User';

interface LoginResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
  refresh_token?: string;
  scope: string;
}

class UserServiceController {
  private licenseManagerUrl = process.env.LICENSE_MANAGER_URL ?? (window.location.protocol + "//" + window.location.hostname + "/license_manager");

  private identityServerUrl = process.env.IDENTITY_SERVER_URL ?? (window.location.protocol + "//" + window.location.hostname + "/identity");

  public Put = {
    confirmUserEmail: (cb: string) => {
      const url = decodeURI(cb);
      return Put(url);
    },
    forgotPassword: (cb: string, password: string) => {
      let url = decodeURI(cb);
      if (url.indexOf(location.protocol) == -1) {
        if (url.indexOf("https") == -1 && location.protocol.indexOf('https') != -1){
          url = url.replace('http', 'https');
        }
      }
      const formData = new FormData();
      formData.append('password', password);
      return axios.put(url, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    },
    confirmInvitation: (orgId: string, accept: boolean) => (
      Put(`${this.licenseManagerUrl}/users/current/organizations/${orgId}/invitationStatus?accept=${accept}`)
    ),
  };

  public Get = {
    fetchCurrentUserAvatar: (options: AxiosRequestConfig = {}) => Get(`${this.licenseManagerUrl}/users/current/avatar`, options),
    fetchCurrentUser: (options: AxiosRequestConfig = {}) => {
      const state = store.getState();
      const userEmail = UserSelectors.getEmail(state);
      return Get(`${this.licenseManagerUrl}/users/${userEmail}`, options);
    },
    fetchCurrentUserOrganizations: (options: AxiosRequestConfig = {}) => Get(`${this.licenseManagerUrl}/users/current/organizations`, options),
    fetchCurrentUserLicenses: (options: AxiosRequestConfig = {}) => Get(`${this.licenseManagerUrl}/users/current/licenses`, options),
    fetchCurrentUserPreferences: (options: AxiosRequestConfig = {}) => Get(`${this.licenseManagerUrl}/users/current/preferences/currentClient`, options),
    fetchUserByEmail: (email: string, options: AxiosRequestConfig = {}) => Get(`${this.licenseManagerUrl}/users/${email}`, options),
    fetchUserLicenses: (userId: string, options: AxiosRequestConfig = {}) => Get(`${this.licenseManagerUrl}/users/${userId}/licenses`, options),
    fetchTokenForPasswordReset: (options: AxiosRequestConfig = {}) => Get(`${this.licenseManagerUrl}/users/current/password/token`, options),
  };

  public Post = {
    registerUser: (data: RegisterUserData, options: AxiosRequestConfig = {}) => Post(`${this.licenseManagerUrl}/users`, data, options),
    resetPasswordEmail: (email: string, options: AxiosRequestConfig = {}) => Post(`${this.licenseManagerUrl}/users/${email}/notify/passwordreset`, {}, options),
    login: (formValues: any, options: AxiosRequestConfig = {}): AxiosPromise<AxiosResponse<LoginResponse> | AxiosError> => {
      const defaultData = {
        client_id: 'Admin',
        grant_type: 'password',
        scope: 'API',
        ...formValues,
      };
      const data = qs.stringify(defaultData);
      const requestOptions = merge({
        params: {
          ...defaultData,
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
          Accept: 'application/x-www-form-urlencoded',
        },
      }, options);
      return Post(
        `${this.identityServerUrl}/connect/token`,
        data,
        requestOptions,
      );
    },
    currentUserPreferences: (data: UserPreferencesData, options: AxiosRequestConfig = {}) => Post(`${this.licenseManagerUrl}/users/current/preferences`, data, options),
  };
}

export const UserService = new UserServiceController();
