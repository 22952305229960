import React, { FC, SVGProps } from 'react';
import { CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import { SadFaceSvg } from './SadFaceSvg';
import { CxvIconSvg } from './CxvIcon';

interface IconProps extends SVGProps<any> {
  name: keyof IconLabel;
}

export interface IconLabel {
  sadFace: FC<SVGProps<any>>;
  checkCircleFilled: any;
  warningFilled: any;
  cxvBlue: FC<SVGProps<any>>;
}

const Icons:IconLabel = {
  sadFace: SadFaceSvg,
  checkCircleFilled: CheckCircleFilled,
  warningFilled: WarningFilled,
  cxvBlue: CxvIconSvg,
};

export const Icon = ({ name, ...rest }:IconProps) => {
  const SVGIcon = Icons[name];
  return (<SVGIcon {...rest} />);
};

Icon.defaultProps = {
  size: 16,
  style: {},
};
