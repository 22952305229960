import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserService } from '~/Services';
import { refreshAccessToken } from '~/Services/AuthIntercept';
import { UserPreferencesData } from '~/Services/UserService.d';
import { useAppDispatch } from '~/Store/hooks';
import { WaitOverlayActions } from '~/Store/Overlays';
import { UserActions, UserSelectors } from '~/Store/User';
import { WaitOverlay } from '../Overlays';
import { Utils } from '~/Utils';
import styles from '~/Views/Main/Main.module.less';

interface LoginWorkFlowProps {
  children: React.ReactNode
}

export const LoginWorkFlow = ({ children }: LoginWorkFlowProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const authenticated = useSelector(UserSelectors.isAuthenticated);
  const organizations = useSelector(UserSelectors.getUserOrganizations);
  const preferences = useSelector(UserSelectors.getUserPreferences);
  const defaultOrg = useSelector(UserSelectors.getDefaultOrganization);
  const refreshToken = useSelector(UserSelectors.getRefreshToken);
  const currentUser = useSelector(UserSelectors.getCurrentUser);
  const [loginProcess, setLoginProcess] = useState(true);

  const licenseManagerUrl = process.env.LICENSE_MANAGER_URL;
  const waitOverlayName = 'loginFlowOverlay';

  const fetchUserProfile = async () => {
    if (!authenticated) return;
    try {
      dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: true }));
      const res = await UserService.Get.fetchCurrentUser();
      const { data } = res;
      dispatch(UserActions.setCurrentUser(data));
    } catch (error) {
      console.log(error);
      setLoginProcess(false);
    } finally {
      dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: false }));
    }
  };

  const fetchOrganizations = async () => {
    if (!authenticated || !currentUser || currentUser.forcePasswordChange) return;
    try {
      dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: true }));
      const res = await UserService.Get.fetchCurrentUserOrganizations();
      dispatch(UserActions.setUserOrganizations(res.data));
      if (Array.from(res.data as any).length === 0) setLoginProcess(false);
    } catch (error) {
      // TODO: handle error
      // console.log(err);
      setLoginProcess(false);
    } finally {
      dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: false }));
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, [authenticated]);

  const forceResetToken = async () => {
    if (!currentUser || !currentUser.forcePasswordChange) return;
    try {
      dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: true }));
      // fetch token for password reset
      const res: any = await UserService.Get.fetchTokenForPasswordReset();
      const { data } = res;
      if (data) {
        const token = Utils.encodeRFC5987ValueChars(data); // encode token param
        const cb = encodeURIComponent(`${licenseManagerUrl}/users/${currentUser.id}/password/reset?token=${token}`);
        history.push(`/passwordreset?cb=${cb}`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: false }));
    }
  };

  const storeCurrentUserPreferences = async (_data: UserPreferencesData) => {
    try {
      const res: any = await UserService.Post.currentUserPreferences(_data);
      const { data } = res;
      dispatch(UserActions.setUserPreferences(data));
      const org = organizations.find((x) => x.guid === data.defaultOrganization);
      dispatch(UserActions.setDefaultOrganization(org));
    } catch (err) {
      console.log('error store current preferences: ', err);
      setLoginProcess(false);
      dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: false }));
    }
  };

  useEffect(() => {
    forceResetToken();
  }, [currentUser]);

  useEffect(() => {
    // automatically pull all of the organizations
    fetchOrganizations();
  }, [authenticated, currentUser]);

  const findDefaultOrganization = async () => {
    if (organizations.length === 0) return;
    try {
      dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: true }));
      const res: any = await UserService.Get.fetchCurrentUserPreferences();
      const { data } = res;
      dispatch(UserActions.setUserPreferences(data));
      // default organization is not available
      if (!data.defaultOrganization) {
        const firstOrg = organizations[0];
        await storeCurrentUserPreferences({ ...data, defaultOrganization: firstOrg.guid });
        return;
      }
      //
      const org = organizations.find((x) => x.guid === data.defaultOrganization);
      dispatch(UserActions.setDefaultOrganization(org));
      if (!org) {
        dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: false }));
      }
    } catch (error) {
      console.log(error);
      // user preferences do not exist
      const firstOrg = organizations[0];
      await storeCurrentUserPreferences({ settings: '', defaultOrganization: firstOrg.guid });
    } finally {
      setLoginProcess(false);
    }
  };

  useEffect(() => {
    findDefaultOrganization();
  }, [organizations.length]);

  const organizationRefreshToken = async () => {
    if (!refreshToken || !defaultOrg) return;
    setLoginProcess(true);
    dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: true }));
    await refreshAccessToken(refreshToken);
    dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: false }));
    setLoginProcess(false);
  };

  useEffect(() => {
    organizationRefreshToken();
  }, [defaultOrg]);

  const changeDefaultOrganization = async () => {
    // if user change default organization => bring up the organization selector workflow
    if (!preferences || !defaultOrg) return;
    if (defaultOrg.guid === preferences.defaultOrganization) return;
    dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: true }));
    storeCurrentUserPreferences({ ...preferences, defaultOrganization: preferences.defaultOrganization });
    dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: false }));
  };

  useEffect(() => {
    changeDefaultOrganization();
  }, [preferences?.defaultOrganization, defaultOrg]);

  return (
    <WaitOverlay
      name={waitOverlayName}
      size="large"
      wrapperClassName={styles.FullOverlay}
    >
      {!loginProcess && children}
    </WaitOverlay>
  );
};
