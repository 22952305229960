import React, { FC } from 'react';
import { Card, Spin, Typography } from 'antd';
import { Icon, IconLabel } from '../Icons/Icon';
import styles from './StatusCard.module.less';

const { Title } = Typography;

export enum StatusCardState {
  Success,
  Error,
  Waiting,
  Warning,
}

interface StatusCardProps {
  state: StatusCardState
}

export const StatusCard: FC<StatusCardProps> = ({ state }) => {
  let titleString;
  let IconString: keyof IconLabel = 'checkCircleFilled';
  let stateClass = '';
  if (state === StatusCardState.Success) {
    titleString = 'Success';
    IconString = 'checkCircleFilled';
    stateClass = styles.success;
  }
  if (state === StatusCardState.Error) {
    titleString = 'Oops';
    IconString = 'sadFace';
    stateClass = styles.error;
  }
  if (state === StatusCardState.Waiting) {
    titleString = 'Please Wait';
    stateClass = styles.waiting;
  }
  if (state === StatusCardState.Warning) {
    titleString = 'Uh Oh';
    IconString = 'warningFilled';
    stateClass = styles.warning;
  }
  return (
    <Card className={`${styles.CardBody} ${stateClass}`}>

      <Title level={4} className={styles.CardTitle}>{titleString}</Title>
      {state === StatusCardState.Waiting && <Spin size="large" />}
      {state !== StatusCardState.Waiting && <Icon name={IconString} className={`${styles.Icon}`} />}

    </Card>
  );
};
