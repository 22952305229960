import React, { ReactNode } from 'react';
import { RouteProps } from 'react-router-dom';
import User from 'remixicon-react/UserLineIcon';
import Building from 'remixicon-react/Building4LineIcon';
import { Organizations } from './Organizations';
import { Profile } from '~/Views/Main/Profile';

import { Info } from '~/Views/Main/Organizations/Info';
import { Users } from '~/Views/Main/Organizations/Users';
import { Billing } from '~/Views/Main/Organizations/Billing';
import { Licenses } from '~/Views/Main/Organizations/Licenses';

export const enum RootClaim {
  System = 'System',
  Admin = 'Admin',
  User = 'User',
}

export const enum OrgClaim {
  Member = 'Member',
  Admin = 'Admin',
  Superuser = 'SuperUser',
}

interface RouteObj {
  label: string;
  icon?: ReactNode;
  rootPermission: RootClaim[]
  orgPermission: OrgClaim[],
  routeProps: RouteProps;
  documentTitle?: string;
}

export const Routes: RouteObj[] = [
  {
    label: 'Organizations',
    rootPermission: [RootClaim.System, RootClaim.Admin],
    icon: <Building />,
    orgPermission: [OrgClaim.Member, OrgClaim.Superuser, OrgClaim.Admin],
    routeProps: {
      path: '/Organizations',
      children: <Organizations />,
    },
  },
  {
    label: 'My Profile',
    rootPermission: [RootClaim.System, RootClaim.Admin, RootClaim.User],
    orgPermission: [],
    icon: <User />,
    routeProps: {
      path: '/profile',
      children: <Profile />,
    },
  },
];

export const OrgRoutes: RouteObj[] = [
  {
    label: 'Info',
    rootPermission: [RootClaim.System, RootClaim.Admin],
    orgPermission: [OrgClaim.Member, OrgClaim.Superuser, OrgClaim.Admin],
    routeProps: {
      path: '',
      children: <Info />,
    },
  },
  {
    label: 'Users',
    rootPermission: [RootClaim.System, RootClaim.Admin],
    orgPermission: [OrgClaim.Member, OrgClaim.Superuser, OrgClaim.Admin],
    routeProps: {
      path: '/users',
      children: <Users />,
    },
  },
  // {
  //   label: 'Billing',
  //   rootPermission: [RootClaim.System, RootClaim.Admin],
  //   orgPermission: [OrgClaim.Admin],
  //   routeProps: {
  //     path: '/billing',
  //     children: <Billing />,
  //   },
  // },
  {
    label: 'Licenses',
    rootPermission: [RootClaim.System, RootClaim.Admin],
    orgPermission: [OrgClaim.Superuser, OrgClaim.Admin],
    routeProps: {
      path: '/licenses',
      children: <Licenses />,
    },
  },
]
