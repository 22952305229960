import React, { useState } from 'react';
import {
  Button, Col, Divider, Form, Input, Row, Space, Typography,
} from 'antd';
import Text from 'antd/es/typography/Text';
import { useHistory, useLocation } from 'react-router-dom';

import { UserService } from '~/Services';

import styles from '../Form.module.less';

export const Register = () => {
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  const [error, setErrorState] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const registerAndAuthenticate = async (values: any) => {
    setErrorState(false);
    setLoading(true);
    try {
      const {
        firstName, lastName, userName, password,
      } = values;
      await UserService.Post.registerUser({
        firstName, lastName, email: userName, userName, password,
      });
      setLoading(false);
      history.replace('/registerconfirmation');
    } catch (err:any) {
      if (err.isAxiosError) {
        const { status, message } = err.toJSON();
        if (status === 400) { setErrorMsg('Bad Request. User already exists!'); } else { setErrorMsg(message); }
      } else {
        setErrorMsg('An unknown issue has occured');
      }
      setErrorState(true);
      setLoading(false);
    }
  };

  const onRedirectToLogin = () => { history.push('/login'); }

  return (
    <Row justify='space-around' align='middle' style={{ height: '100%' }}>
      <Col sm={24} md={18} lg={16} xl={12} xxl={8}>
        <div className={styles.form} style={{ height: '600px' }}>
          <div className={styles.header}>
            <Typography.Title level={4}>Đăng ký</Typography.Title>
            <span>Tạo tài khoản để sử dụng các dịch vụ của Hicas</span>
          </div>
          <Form
            layout="vertical"
            name="register"
            form={form}
            scrollToFirstError
            onFinish={registerAndAuthenticate}
            autoComplete="off"
            requiredMark={false}
          >
            <Form.Item
              label="Tên"
              name="firstName"
              rules={[{ required: true, message: 'Nhập tên của bạn' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Họ và Tên đệm"
              name="lastName"
              rules={[{ required: true, message: 'Nhập họ và tên đệm' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Địa chỉ email"
              name="userName"
              rules={[
                { type: 'email', message: 'Email không đúng định dạng' },
                { required: true, message: 'Nhập email của bạn' }]}
              extra="Chúng tôi sẽ dùng email này để liên hệ với bạn"
            >
              <Input />
            </Form.Item>
            <Space direction="vertical">
              <Text type="danger">
                {error && errorMsg}
              </Text>
            </Space>
            <Form.Item>
              <Row>
                <Col span={14} style={{paddingTop: '4px'}}>
                  <span>Bạn đã có tài khoản chưa?</span>
                  <Button key="loginButton" htmlType="button" disabled={loading} type='link' onClick={onRedirectToLogin}>Đăng nhập</Button>
                </Col>
                <Col span={10} style={{ textAlign: 'right' }}>
                <Button key="confirmButton" form="register" disabled={loading} htmlType="submit" type="primary" size='large' loading={loading}>Đăng ký</Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
          <Divider />
          <div className={styles.footer}>
            <div>By continuing you agree to Hicas's <a href="">Terms of Service</a> and</div>
            <div><a href="">Privacy Policy</a> and to receiving email with updates.</div>
          </div>
        </div>
      </Col>
    </Row>
  );
};
