import React, { FC, SVGProps } from 'react';

export const StdCertificateSvg: FC<SVGProps<any>> = ({ height, width }) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width={width || "32"} height={height || "32"} viewBox="0 0 256 256" xmlSpace="preserve">
    <g transform="translate(128 128) scale(0.72 0.72)">
      <g style={{stroke: 'none', strokeWidth: 0, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'none', fillRule: 'nonzero', opacity: 1}} transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)" >
        <path d="M 44.759 63.847 L 56.954 90 l 9.909 -9.079 l 13.325 -1.755 L 68.323 53.721 c -9.609 -0.082 -17.439 3.046 -23.082 10.126" style={{stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(237,99,98)', fillRule: 'nonzero', opacity: 1}} transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round" />
        <path d="M 45.241 63.847 L 33.046 90 l -9.909 -9.079 L 9.812 79.166 l 11.865 -25.445 C 33.52 52.826 41.539 56.026 45.241 63.847 z" style={{stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(233,84,84)', fillRule: 'nonzero', opacity: 1}} transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round" />
        <path d="M 45 63.847 c 17.631 0 31.923 -14.293 31.923 -31.923 S 62.631 0 45 0 C 34.483 21.782 33.645 43.104 45 63.847 z" style={{stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(255,204,91)', fillRule: 'nonzero', opacity: 1}} transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round" />
        <path d="M 45 63.847 c -17.631 0 -31.923 -14.293 -31.923 -31.923 S 27.369 0 45 0 v 63.814" style={{stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(253,188,75)', fillRule: 'nonzero', opacity: 1}} transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round" />
        <path d="M 38.778 54.411 c -0.092 0 -0.186 -0.013 -0.279 -0.039 C 26.122 50.786 18.967 37.8 22.552 25.422 c 0.153 -0.531 0.707 -0.836 1.239 -0.682 c 0.53 0.153 0.836 0.708 0.682 1.239 c -3.277 11.318 3.264 23.193 14.583 26.471 c 0.53 0.154 0.836 0.709 0.682 1.239 C 39.611 54.127 39.211 54.411 38.778 54.411 z" style={{stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(236,153,34)', fillRule: 'nonzero', opacity: 1}} transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round" />
        <path d="M 66.487 39.146 c -0.092 0 -0.186 -0.013 -0.278 -0.04 c -0.53 -0.154 -0.836 -0.708 -0.683 -1.239 c 1.589 -5.483 0.946 -11.256 -1.808 -16.256 c -2.755 -5 -7.291 -8.628 -12.774 -10.216 c -0.53 -0.153 -0.836 -0.708 -0.683 -1.239 c 0.154 -0.53 0.708 -0.835 1.239 -0.682 c 5.996 1.736 10.957 5.704 13.969 11.172 c 3.013 5.468 3.715 11.781 1.979 17.777 C 67.321 38.862 66.922 39.146 66.487 39.146 z" style={{stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(236,153,34)', fillRule: 'nonzero', opacity: 1}} transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round" />
        <path d="M 40.62 45.273 c -0.568 0 -1.132 -0.233 -1.547 -0.69 l -6.517 -7.176 c -0.351 -0.385 -0.544 -0.885 -0.544 -1.406 v -4.727 c 0 -0.871 0.525 -1.637 1.338 -1.951 c 0.813 -0.314 1.716 -0.099 2.302 0.545 l 4.902 5.397 c 0.034 0.036 0.09 0.039 0.127 0.008 l 13.854 -11.902 c 0.626 -0.54 1.481 -0.66 2.234 -0.314 c 0.752 0.345 1.219 1.073 1.219 1.9 v 5.096 c 0 0.61 -0.266 1.188 -0.728 1.586 L 41.984 44.763 C 41.586 45.105 41.102 45.273 40.62 45.273 z" style={{stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(236,153,34)', fillRule: 'nonzero', opacity: 1}} transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round" />
      </g>
    </g>
  </svg>
);
