import React, { FC } from 'react';
import { Dropdown, Menu, Tag, Button  } from 'antd';
import { DownOutlined } from '@ant-design/icons';

interface DropOptionProps {
  title: string;
  color?: string;
  onMenuClick: (info: {}) => void,
  menuOptions: { key: any; name: string }[];
  dropdownProps?: {};
  buttonStyle?: {};
}

export const DropOption: FC<DropOptionProps> = (props) => {
  const { title, color, onMenuClick, menuOptions, dropdownProps, buttonStyle } = props;

  const menu = menuOptions.map((item) => (
    <Menu.Item key={item.key}>{item.name}</Menu.Item>
  ));

  return (
    <Dropdown
      overlay={<Menu onClick={onMenuClick}>{menu}</Menu>}
      {...dropdownProps}
    >
      <Button style={{ border: 'none', ...buttonStyle }}>
        <Tag style={{ marginRight: 2 }} color={color || 'blue'}>{title}</Tag>
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};
