import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { ConfigProvider } from 'antd';
import { Root } from '../Views';
import { ConfirmationHicas } from '~/Views/Hicas/ConfirmationHicas';
import { PasswordResetHicas } from '~/Views/Hicas/ResetPasswordHicas';

export const App = () => (
  <ConfigProvider prefixCls="ant">
    <BrowserRouter basename="/">
      <Switch>
        <Route exact path="/confirmemail" component={ConfirmationHicas} />
        <Route exact path="/passwordreset" component={PasswordResetHicas} />
        <Route path="/" component={Root} />
      </Switch>
    </BrowserRouter>
  </ConfigProvider>
);
