import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface WaitOverlayReducerStore {
  [key: string]: {
    visible: boolean
  }
}

const initialState: WaitOverlayReducerStore = {};

export const WaitOverlaySlice = createSlice({
  name: 'overlayModal',
  initialState,
  reducers: {
    initialize: (state, action: PayloadAction<string>) => {
      state[action.payload] = { visible: false };
    },
    setVisible: (state, action: PayloadAction<{ name: string, value: boolean }>) => {
      const { name, value = false } = action.payload;
      state[name].visible = value;
    },
    toggleVisible: (state, action: PayloadAction<string>) => {
      state[action.payload].visible = !state[action.payload].visible;
    },
  },

});

export const WaitOverlayActions = WaitOverlaySlice.actions;
export const WaitOverlayReducer = WaitOverlaySlice.reducer;
