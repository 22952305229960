import React, { FC } from 'react';
import { GiftBoxSvg, ProCertificateSvg, StdCertificateSvg } from '../SVG';

interface LicenseAvatarProps {
  type: string,
  height: number,
  width: number
}

export const LicenseAvatar: FC<LicenseAvatarProps> = ({ type, height, width }) => {
  const licenseTypes = [
    { type: 'free', avatar: <GiftBoxSvg height={height} width={width} /> },
    { type: 'standard', avatar: <StdCertificateSvg height={height} width={width} /> },
    { type: 'profession', avatar: <ProCertificateSvg height={height} width={width} /> },
    { type: 'premium', avatar: <ProCertificateSvg height={height} width={width} /> }
  ]
  const licenseType = licenseTypes.find(x => type.toLocaleLowerCase().includes(x.type));
  
  return licenseType ? licenseType.avatar : <GiftBoxSvg height={height} width={width} />;
}