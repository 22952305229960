import React, { useState } from 'react';
import {
  Form,
  Row,
  Col,
  Input,
  Popover,
  Radio,
  Button,
  Select,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { getUserFilterState } from '~/Store/Organization/selectors';
import { useAppDispatch } from '~/Store/hooks';
import { OrganizationActions } from '~/Store/Organization';
import { OrgClaim } from '../../Routes';
import { FilterSvg } from '~/Components';

const { Option } = Select;

export const UserFilter = () => {
  const dispatch = useAppDispatch();
  const filterState = useSelector(getUserFilterState);
  const [timer, setTimer] = useState<any>(null);
  const [showAdvancedFilter, setAdvancedFilterVisibility] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    dispatch(
      OrganizationActions.setUserFilterState({
        ...filterState,
        userType: values.userType,
        status: values.status,
      }),
    );
    setAdvancedFilterVisibility(false);
  };

  const queryChange = (filterQuery: string) => {
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      dispatch(
        OrganizationActions.setUserFilterState({
          ...filterState,
          criteria: filterQuery,
        }),
      );
    }, 500);
    setTimer(timeoutId);
  };

  const handleClickStatus = (evt: any) => {
    const values = form.getFieldsValue();
    if (evt.target.value === form.getFieldValue('status')) {
      form.setFieldsValue({ ...values, status: '' });
    }
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const AdvancedFilter = () => (
    <div>
      <Form
        form={form}
        layout="vertical"
        initialValues={{ ...filterState }}
        onFinish={onFinish}
      >
        <Form.Item label="User type" name="userType">
          <Select
            mode="multiple"
            showArrow
          >
            <Option key={OrgClaim.Member}>Member</Option>
            <Option key={OrgClaim.Admin}>Admin</Option>
            <Option key={OrgClaim.Superuser}>Supper User</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Status" name="status">
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
          >
            <Radio.Button onClick={handleClickStatus} value='Accepted'>Accepted</Radio.Button>
            <Radio.Button onClick={handleClickStatus} value='Pending'>Pending</Radio.Button>
            <Radio.Button onClick={handleClickStatus} value='Declined'>Declined</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">
              Filter
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );

  return (
    <Row gutter={10}>
      <Col>
        <Form
          initialValues={{ findQuery: filterState.criteria }}
          onFieldsChange={(_, allFields) => queryChange(allFields[0].value)}
          autoComplete="off"
        >
          <Form.Item name="findQuery">
            <Input prefix={<SearchOutlined />} placeholder="Search" />
          </Form.Item>
        </Form>
      </Col>
      <Col>
        <Popover
          placement="bottomRight"
          content={AdvancedFilter}
          trigger="click"
          visible={showAdvancedFilter}
          onVisibleChange={() => setAdvancedFilterVisibility(!showAdvancedFilter)}
        >
          <div
            style={{
              cursor: 'pointer',
              height: '32px',
              width: '25px',
              paddingTop: '7px',
            }}
          >
            <FilterSvg height={16} width={18} />
          </div>
        </Popover>
      </Col>
    </Row>
  );
};
