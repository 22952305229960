import React, { FC } from 'react';

import { List } from 'antd';

import { ListItemProps } from 'antd/lib/list/Item';
import classNames from 'classnames';
import styles from './ListItemBordered.module.less';

export const ListItemBordered:FC<ListItemProps> = ({ children, className, ...rest }) => {
  console.log(styles);
  return (
    <List.Item className={classNames(styles.ListItemBordered, className)} {...rest}>
      {children}
    </List.Item>
  );
};
