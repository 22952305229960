import React, { useState } from 'react';
import {
  Button, Col, Form, Input, Row, Space, Typography,
} from 'antd';
import { UserService } from '~/Services';

const { Text, Paragraph } = Typography;
export const ForgotPassword = () => {
  const [form] = Form.useForm();
  const [errorMsg, setErrorMsg] = useState('');
  const [error, setErrorState] = useState(false);
  const [loading, setLoadingState] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const sendResetEmail = async ({ email }: any) => {
    setLoadingState(true);
    setErrorMsg('');
    setErrorState(false);
    setSuccessMsg('');

    try {
      await UserService.Post.resetPasswordEmail(email);
      setLoadingState(false);
      setSuccessMsg(`An email has been sent to ${email}`);
      setLoadingState(false);
    } catch (err) {
      setErrorMsg('An issue has occured with your request');
      setLoadingState(false);
      setErrorState(true);
    }
  };
  return (
    <Row>
      <Col offset={6} span={12}>
        <Text>Please enter a valid email address</Text>
        <Paragraph>
          An email will be sent to the provided email address. Please click on the link in the email to visit
          the reset password page.
        </Paragraph>
        <Form
          layout="vertical"
          name="forgotPassword"
          form={form}
          onFinish={sendResetEmail}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { type: 'email', message: 'not a valid email address' },
              { required: true, message: 'Please input email address' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item style={{ textAlign: 'right' }}>
            <Button
              key="confirmButton"
              htmlType="submit"
              disabled={loading}
              loading={loading}
            >
              Reset Password
            </Button>
          </Form.Item>
        </Form>
        <Space direction="vertical">
          {error && (
            <Text type="danger">
&nbsp;
              {errorMsg}
            </Text>
          )}
          <Text type="success">
&nbsp;
            {successMsg}
          </Text>
        </Space>

      </Col>
    </Row>
  );
};
