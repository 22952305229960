import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { LoginModalReducer } from '~/Store/LoginModal';
import { UserReducer } from '~/Store/User';
import { WaitOverlayReducer } from '~/Store/Overlays';
import { LayoutReducer } from '~/Store/Layout';
import { OrganizationReducer } from './Organization';
import { GlobalReducer } from './Global';

const reducer = combineReducers({
  global: GlobalReducer,
  user: UserReducer,
  loginModal: LoginModalReducer,
  waitOverlays: WaitOverlayReducer,
  layout: LayoutReducer,
  organization: OrganizationReducer,
});

export const store = configureStore({
  reducer,
});
