import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LoginModalStore {
  visible: boolean;
  error: boolean;
  errorMsg: string;
}

const initialState: LoginModalStore = {
  visible: false,
  error: false,
  errorMsg: '',
};

export const loginModalSlice = createSlice({
  name: 'loginModal',
  initialState,
  reducers: {
    toggleVisibility: (state) => { state.visible = !state.visible; },
    setErrorState: (state, action:PayloadAction<boolean>) => { state.error = action.payload; },
    setErrorMessage: (state, action:PayloadAction<string>) => { state.errorMsg = action.payload; },
  },
});

export const LoginModalActions = loginModalSlice.actions;
export const LoginModalReducer = loginModalSlice.reducer;
