import React from 'react';
import { Menu } from 'antd';
import {
  Switch,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { OrganizationOverlay, Page, PrivateRoute } from '~/Components';

import styles from './Organizations.module.less';
import { useAppDispatch } from '~/Store/hooks';
import { OrganizationActions } from '~/Store/Organization';
// eslint-disable-next-line import/no-cycle
import { OrgRoutes } from '../Routes';

export const Organizations = () => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useAppDispatch();

  const navigate = (path: string) => {
    if (path !== '') {
      dispatch(OrganizationActions.setIsEditMode(false));
    }
    history.push(`${match.path}${path}`);
  };

  const activeKeys = (): string => {
    const currentTab = OrgRoutes.find(
      ({ routeProps: { path } }) => `${match.path}${path}` === location.pathname,
    );
    return currentTab ? currentTab.label : 'info';
  };

  return (
    <OrganizationOverlay>
      <Menu
        mode="horizontal"
        selectedKeys={[activeKeys()]}
        style={{ background: '#FFF' }}
      >
        {OrgRoutes.map(({ label, routeProps: { path } }) => (
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          <Menu.Item key={label} onClick={() => navigate(path!.toString())}>
            {label}
          </Menu.Item>
        ))}
      </Menu>
      <Page title="Organization" className={styles.Organizations}>
        <Switch>
          {OrgRoutes.map(
            ({
              label,
              rootPermission,
              orgPermission,
              routeProps: { path, ...rest },
            }) => (
              <PrivateRoute
                allowedRoles={[...rootPermission, ...orgPermission]}
                key={`${label}`}
                exact
                path={`${match.path}${path}`}
                {...rest}
              />
            ),
          )}
          <Redirect to="/Organizations" />
        </Switch>
      </Page>
    </OrganizationOverlay>
  );
};
