import {
  AxiosRequestConfig, AxiosResponse, AxiosError, AxiosPromise,
} from 'axios';
import merge from 'lodash.merge';
import { convertStringToCurrentProtocol } from './utils';
import authIntercept from './AuthIntercept';

export const Delete = (url: string, data: any = {}, options: AxiosRequestConfig = {}): AxiosPromise<AxiosResponse | AxiosError> => {
  const nUrl = convertStringToCurrentProtocol(url);

  const defaultOptions = merge({
    method: 'DELETE' as const,
    url: nUrl,
    timeout: 30000,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    data,

  }, options);

  return authIntercept(defaultOptions);
};
