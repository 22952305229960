/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import {
  Typography,
  Pagination,
  Row,
  notification,
} from 'antd';
import { SmileOutlined } from '@ant-design/icons';

import { useSelector } from 'react-redux';
import styles from './Users.module.less';
import { UserItem } from './UserItem';
import { getLeftSiderCollapsed } from '~/Store/Layout/selectors';
import { UserFilter } from './UserFilter';
import { OrganizationsService } from '~/Services/OrganizationService';
import { getDefaultOrganization } from '~/Store/User/selectors';
import { UserData, UserItemData } from '~/Services/UserService.d';
import { getPagination, GlobalActions } from '~/Store/Global';
import { useAppDispatch } from '~/Store/hooks';
import {
  getOrgUserCurrentPage,
  getUserFilterState,
} from '~/Store/Organization/selectors';
import { OrganizationActions } from '~/Store/Organization';
import useModal from '~/Hooks/useModal';
import { RemoveUser } from './RemoveUser';
import { UserService } from '~/Services';
import { WaitOverlay } from '~/Components';
import { WaitOverlayActions } from '~/Store/Overlays';
import { NoData } from '~/Components/NoData';
import { ScrollBar } from '~/Components/ScrollBar';
import { AxiosError } from 'axios';
import useHasPermissions from '~/Hooks/useHasPermissions';
import { OrgClaim, RootClaim } from '../../Routes';

export const Users = () => {
  const dispatch = useAppDispatch();
  const leftMenuCollapsed = useSelector(getLeftSiderCollapsed);
  const defaultOrg = useSelector(getDefaultOrganization);
  const hasAdmin = useHasPermissions([RootClaim.System, OrgClaim.Admin]);
  const pagination = useSelector(getPagination);
  const { pageSize } = pagination;
  const currentPage = useSelector(getOrgUserCurrentPage);
  const [users, setUsers] = useState<UserItemData[]>([]);
  const [totalItem, setTotalItem] = useState(1);
  const filterState = useSelector(getUserFilterState);

  const [isFetching, setFetching] = useState(false);
  const [user, setUser] = useState<UserData>();
  const [licenses, setLicenses] = useState<any[]>([]);
  const [removing, setRemoving] = useState(false);

  const waitOverlayName = 'fetchingOrgUsers';

  const removeUserModal = useModal({
    content: users,
    onOpen: async (item?: UserItemData) => {
      if (item) {
        // fetch user
        try {
          setFetching(true);
          const ps: Promise<any>[] = [];
          ps.push(UserService.Get.fetchUserByEmail(item.email));
          ps.push(UserService.Get.fetchUserLicenses(item.id));
          const res = await Promise.all(ps);
          const { data: userData } = res[0];
          if (userData) setUser(userData);
          const { data: userLicenses } = res[1];
          if (userLicenses && defaultOrg) {
            setLicenses(
              Array.from(userLicenses).filter(
                (x: any) => x.organizationId === defaultOrg.guid,
              ),
            );
          }
        } catch (error) {
          // todo
          console.log(error);
        } finally {
          setFetching(false);
        }
      }
    },
  });

  const fetchOrganizationUsers = async () => {
    if (!defaultOrg) return;
    try {
      dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: true }));
      let statusFilter = '';
      // if (filterState.status) {
      //   statusFilter = `invitationStatus eq '${filterState.status}'`;
      // }
      let queryString = '';
      if (filterState.criteria) {
        queryString += `(contains(userName, '${filterState.criteria}')`;
        queryString += ` or contains(email, '${filterState.criteria}')`;
        queryString += ` or contains(phoneNumber, '${filterState.criteria}')`;
        queryString += ` or contains(firstname, '${filterState.criteria}')`;
        queryString += ` or contains(lastname, '${filterState.criteria}'))`;
      }
      const res: any = await OrganizationsService.Get.getOrganizationUsers(
        defaultOrg.guid,
        {
          filter: [statusFilter, queryString].filter(x => x).join(' and ') || undefined,
          expand: 'Licenses',
          page: currentPage,
          pageSize
        },
        filterState.userType.length > 0 ? filterState.userType : undefined,
        filterState.status ? [filterState.status] : undefined
      );
      const { data } = res;
      const { page, queryCount, results } = data;
      setUsers(results);
      dispatch(OrganizationActions.setUsersCurrentPage(page));
      setTotalItem(queryCount);
    } catch (error: any) {
      const { message } = error;
      notification.error({
        message: 'Oops!',
        description: message
      })
    } finally {
      dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: false }));
    }
  };

  const handleRemoveUser = async (values: Partial<UserData>) => {
    try {
      setRemoving(true);
      await OrganizationsService.Delete.removeUsers(defaultOrg!.guid, [
        values.id!,
      ]);
      // refetch users and close modal
      await fetchOrganizationUsers();
      notification.open({
        message: 'Remove user',
        description: 'Remove successfully.',
        icon: <SmileOutlined style={{ color: '#108ee9' }} />,
      });
      removeUserModal.close();
    } catch (error) {
      // todo
      console.log(error);
    } finally {
      setRemoving(false);
    }
  };

  const changeUserStatus = async (email: string, status: string) => {
    if (!defaultOrg) return;
    try {
      await OrganizationsService.Post.changeUserStatus(defaultOrg.guid, [{ email, status }]);
      //  refetch users
      await fetchOrganizationUsers();
    } catch (error: any) {
      const { message } = error;
      if (message) {
        notification.error({
          message: 'Oops!',
          description: message,
        });
      }
      console.log(error);
    }
  };

  useEffect(() => {
    fetchOrganizationUsers();
  }, [defaultOrg, currentPage, pageSize, filterState]);

  const changePage = (_page: number, _pageSize: number) => {
    dispatch(OrganizationActions.setUsersCurrentPage(_page));
    dispatch(GlobalActions.setPagination({ ...pagination, pageSize: _pageSize }));
  };

  return (
    <WaitOverlay
      name={waitOverlayName}
      size="large"
      wrapperClassName={styles.FullOverlay}
    >
      {removeUserModal.visible && (
        <RemoveUser
          user={user || {}}
          licenses={licenses}
          loadingData={isFetching}
          onOk={handleRemoveUser}
          onCancel={removeUserModal.close}
          confirmLoading={removing}
          footer={null}
        />
      )}
      <div className={styles.OrganizationUsers}>
        <Row align="stretch">
          <Typography.Title style={{ flex: 1 }} level={4}>
            Organization Users
          </Typography.Title>
          <UserFilter />
        </Row>
        <div className={styles.UserList}>
          <ScrollBar>
            {users.length > 0 ? (
              users.map((item: UserItemData) => (
                <UserItem
                  key={item.id}
                  user={item}
                  onClick={removeUserModal.toggle}
                  changeUserStatus={changeUserStatus}
                  hasAdmin={hasAdmin}
                />
              ))
            ) : (
              <NoData content="No data" />
            )}
          </ScrollBar>
        </div>
        <div
          className={styles.Pagination}
          style={{
            width: `calc(100% - ${leftMenuCollapsed ? '146' : '266'}px)`,
          }}
        >
          <Pagination
            current={currentPage}
            total={totalItem}
            pageSize={pageSize}
            showSizeChanger
            responsive
            onChange={changePage}
          />
        </div>
      </div>
    </WaitOverlay>
  );
};
