import React, { useEffect, useState } from 'react';
import {
  Col, Layout, Row, Typography,
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { CxvLogoSvg, StatusCard, StatusCardState } from '../../Components';
import { UserService } from '~/Services';
import { Utils } from '~/Utils';

const { Put } = UserService;
const { Title, Paragraph } = Typography;
const { Content } = Layout;

export const ConfirmationView = () => {
  const history = useHistory();
  const location = useLocation();
  const [status, setStatus] = useState({
    status: StatusCardState.Waiting,
    title: 'Please Wait while we confirm your account',
    body: 'It will only take us a moment to verify your account',

  });

  const setSuccessState = ({ title, body }: { [key: string]: string } = {}) => {
    setStatus(
      {
        status: StatusCardState.Success,
        title: title || 'Thank you for confirming your account',
        body: body || 'You may now close this tab and go back to the Construxiv app to log in. This will give you access to all the features',
      },
    );
  };

  const setErrorState = ({ title, body }: { [key: string]: string } = {}) => {
    setStatus(
      {
        status: StatusCardState.Error,
        title: title || 'Oops! Looks like something went wrong',
        body: body || 'Please try to confirm again by clicking the link or re-registering your account inside tha Construxiv application.',
      },
    );
  };

  const verifyEmail = async (): Promise<void> => {
    const params = new URLSearchParams(location.search);
    const cb = params.get('cb');

    if (!cb) {
      setErrorState({
        body: 'Looks like we are missing some information! If you copied and pasted from your email make sure you copied the entire link!',
      });
    } else {
      try {
        await Put.confirmUserEmail(cb);
        setSuccessState({
          body: 'Please wait this page will redirect to login Or You may now close this tab and go back to the Construxiv app to log in. This will give you access to all the features.'
        });
        // pause 10 seconds then navigate to /login
        await Utils.delay(10000);
        history.push('/login');
      } catch (err: any) {
        if (err.response) {
          if (err.response.status === 404) {
            setErrorState();
          } else {
            setErrorState();
          }
        } else {
          setErrorState();
        }
      }
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <Layout style={{ height: '100vh' }}>
      <Content style={{ margin: 24 }}>
        <Row>
          <Col span={12} offset={6}>
            <Row>
              <CxvLogoSvg width={200} />
            </Row>
            <Row gutter={48} justify="space-around" align="middle">
              <Col span={12}>
                <Title level={1}>{status.title || ''}</Title>
                <Paragraph>
                  {status.body}
                </Paragraph>
              </Col>
              <Col span={12}>
                <StatusCard state={status.status} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>

  );
};
