import {
  Avatar,
  Col,
  Row,
  Tag,
} from 'antd';
import React, { FC } from 'react';
import { LicenseData } from '~/Store/Organization';
import { Utils } from '~/Utils';
import styles from './Info.module.less';

export interface OrganizationItemData {
  billingAddress: string,
  billingAddress2: string,
  billingCity: string,
  billingState: string,
  billingZip: string,
  companyName: string,
  crmId?: string,
  duns?: string,
  guid: string,
  invitationStatus: string,
  licenses: LicenseData[]
  naics?: string,
  avatarUrl?: string
}
interface OrganizationItemProps {
  org: OrganizationItemData;
  licenseName: string
}

export const OrganizationItem: FC<OrganizationItemProps> = ({ org, licenseName }) => (
  <Col
    xl={12}
    xs={24}
  >
    <Row align="middle" className={styles.OrgRow}>
      <Col className={styles.OrganizationItemAvatar}>
        <Avatar
          shape='square'
          size={50}
          src={org.avatarUrl}
          style={{
            backgroundColor: org.avatarUrl
              ? undefined
              : Utils.stringToColour(org.guid),
          }}
        >
          {org.companyName.charAt(0).toLocaleUpperCase()}
        </Avatar>
      </Col>
      <Col>
        <Row>
          <strong>{org.companyName}</strong>
          {licenseName !== '' ? (
            <div className={styles.InivationStatus}>
              <Tag color="green">
                {licenseName}
              </Tag>
            </div>
          )
            : null}
        </Row>
        <Row>
          {org.billingAddress}
        </Row>
      </Col>
    </Row>
  </Col>
);
