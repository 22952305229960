import React, { FC, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Spin,
  Popover,
  Row,
  Select,
  List,
  Avatar,
  Divider,
  notification,
} from 'antd';
import { useSelector } from 'react-redux';
import { OrganizationsService } from '~/Services/OrganizationService';
import { UserItemData } from '~/Services/UserService.d';
import { getDefaultOrganization } from '~/Store/User/selectors';
import { Utils } from '~/Utils';
import styles from './Licenses.module.less';

const { Option } = Select;
interface AddUserProps {
  licenseId: string;
  refetchOrgLicenses: () => Promise<void>;
  openLicensePanel: (key: string[] | string | undefined) => void;
}

export const AddUser: FC<AddUserProps> = ({
  licenseId,
  refetchOrgLicenses,
  openLicensePanel,
}) => {
  const [form] = Form.useForm();
  const defaultOrg = useSelector(getDefaultOrganization);
  const [showAddUser, setShowAddUser] = useState<boolean>(false);
  const [timer, setTimer] = useState<any>(null);
  const [users, setUsers] = useState<UserItemData[]>([]);
  const [fetching, setFetching] = useState(false);
  const [invitationList, setInvitationList] = useState<UserItemData[]>([]);
  const [inviting, setInviting] = useState(false);
  const [findQuery, setFindQuery] = useState('');

  const fetchOrganizationUsers = async (str: string) => {
    if (!defaultOrg) return;
    try {
      setFetching(true);
      const res: any = await OrganizationsService.Get.getOrganizationUsers(
        defaultOrg.guid,
        {
          filter: `contains(email, '${str}') or contains(firstName, '${str}') or contains(lastName, '${str}')`,
          paging: false,
        },
      );
      const { data } = res;
      setUsers(data);
    } catch (error) {
      console.log(error);
    } finally {
      setFetching(false);
    }
  };

  const toggleAddUser = () => {
    if (showAddUser) {
      setUsers([]);
      setInvitationList([]);
      form.resetFields();
      setFindQuery('');
    }
    setShowAddUser(!showAddUser);
  };

  const addUsersToInvitation = (values: any) => {
    const { userEmail } = values;
    const user = users.find((i) => i.email === userEmail);
    if (user) setInvitationList([...invitationList, user]);
    setFindQuery('');
    setUsers([]);
    form.resetFields();
  };

  const saveInvitationList = async () => {
    try {
      setInviting(true);
      const ps: Promise<any>[] = [];
      invitationList.forEach((i) => ps.push(OrganizationsService.Post.addOrgLicenseToUsers(defaultOrg!.guid, i.id, [licenseId])));
      await Promise.all(ps);
      await refetchOrgLicenses();
      toggleAddUser();
    } catch (error: any) {
      notification.error({
        message: 'Oops!',
        description: error.message,
      });
      // todo
    } finally {
      setInviting(false);
    }
  };

  const findUsers = async (str: string) => {
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      setFindQuery(str);
      if (!str) {
        setUsers([]);
      } else {
        fetchOrganizationUsers(str);
      }
    }, 500);
    setTimer(timeoutId);
  };

  const options = users.map((d) => (
    <Option key={d.id} value={d.email} label={`${d.firstname} ${d.lastname}`}>
      <List.Item.Meta
        avatar={(
          <Avatar
            src={
              d.avatarUrl && !d.avatarUrl.startsWith('http')
                ? `https://${d.avatarUrl}`
                : undefined
            }
            style={{
              backgroundColor: d.avatarUrl
                ? undefined
                : Utils.stringToColour(d.id),
            }}
          >
            {(d.firstname || d.email).charAt(0).toLocaleUpperCase()}
          </Avatar>
        )}
        title={`${d.firstname} ${d.lastname}`}
        description={d.email}
        style={{ marginBottom: '5px' }}
      />
    </Option>
  ));

  const addUser = () => (
    <div className={styles.addUserPopup}>
      {invitationList.length > 0 && (
        <>
          <List
            itemLayout="horizontal"
            dataSource={invitationList}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  key={item.id}
                  avatar={(
                    <Avatar
                      src={
                        item.avatarUrl && !item.avatarUrl.startsWith('http')
                          ? `https://${item.avatarUrl}`
                          : undefined
                      }
                      style={{
                        backgroundColor: item.avatarUrl
                          ? undefined
                          : Utils.stringToColour(item.id),
                      }}
                    >
                      {(item.firstname || item.email)
                        .charAt(0)
                        .toLocaleUpperCase()}
                    </Avatar>
                  )}
                  title={`${item.firstname} ${item.lastname}`}
                  description={item.email}
                />
              </List.Item>
            )}
          />
          <Divider style={{ margin: '0px 0px 10px 0px' }} />
        </>
      )}
      <Form
        form={form}
        layout="vertical"
        onFinish={addUsersToInvitation}
        requiredMark={false}
      >
        <Form.Item
          label="User email"
          name="userEmail"
          rules={[{ required: true, message: 'This field is required!' }]}
        >
          <Select
            showSearch
            placeholder="example@gmail.com"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={findUsers}
            optionLabelProp="label"
            allowClear
            disabled={inviting}
            notFoundContent={!findQuery ? 'Input search text' : 'Not Found'}
            autoFocus
          >
            {fetching
              ? [
                <Option key="loading">
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <Spin size="small" />
                  </div>
                </Option>,
              ]
              : options}
          </Select>
        </Form.Item>
        <Form.Item>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              {invitationList.length > 0 ? (
                <>
                  <Button type="link" htmlType="submit" disabled={inviting}>
                    Add more users
                  </Button>
                  <Button
                    shape="round"
                    type="primary"
                    style={{ marginLeft: '8px' }}
                    onClick={saveInvitationList}
                    loading={inviting}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <Button shape="round" type="primary" htmlType="submit">
                  Add users
                </Button>
              )}
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <div
      onClick={(e) => {
        openLicensePanel(licenseId);
        e.stopPropagation();
      }}
      className={styles.extraAddUser}
    >
      <Popover
        placement="leftTop"
        content={addUser}
        trigger="click"
        title="Add user"
        visible={showAddUser}
        onVisibleChange={toggleAddUser}
      >
        <PlusOutlined className={styles.addIcon} />
      </Popover>
    </div>
  );
};
