import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
} from 'antd';
import { AxiosResponse } from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch } from '~/Store/hooks';
import { UserService } from '~/Services';
import { UserActions } from '~/Store/User';
import styles from './Form.module.less';

const { Text, Link } = Typography;
export const Login = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const [error, setErrorState] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [form] = Form.useForm();

  const fetchToken = async (values: any) => {
    const { remember, ...rest } = values;
    setErrorState(false);
    try {
      setErrorState(false);
      // need to get a refresh token to organization refresh token
      rest.scope = 'API offline_access';
      const res: AxiosResponse = await UserService.Post.login(rest);
      const { data } = res;
      if (data.refresh_token && remember) {
        localStorage.setItem('refresh_token', data.refresh_token);
      }
      dispatch(UserActions.setToken(data.access_token));
      dispatch(UserActions.setRefreshToken(data.refresh_token || ''));
      const { from } = location.state || { from: { pathname: '/' } } as any; // LocationState

      history.replace(from && from.pathname === '/login' ? { from: { pathname: '/' } } : from);
    } catch (err: any) {
      setErrorState(true);
      let msg = '';
      if (err.isAxiosError) {
        const { status } = err.toJSON();

        if (status === 400) {
          msg = 'Sai mật khẩu hoặc tên đang nhập';
        } else {
          msg = 'Có một lỗi xảy ra trong quá trình đăng nhập';
        }
        setErrorMsg(msg);
        setErrorState(true);
      }
    }
  };
  return (
    <Row justify='space-around' align='middle' style={{ height: '100%' }}>
      <Col sm={24} md={18} lg={16} xl={12} xxl={8}>
        <div className={styles.form} style={{ height: '455px' }}>
        <div className={styles.header}>
            <Typography.Title level={4}>Đăng nhập</Typography.Title>
            <span>Đăng nhập tài khoản của bạn vào hệ thống</span>
          </div>
          <Form
            layout="vertical"
            name="login"
            form={form}
            onFinish={fetchToken}
            requiredMark={false}
          >
            <Form.Item
              label="Email"
              name="username"
              rules={[
                { type: 'email', message: 'Em mail không đúng định dạng' },
                { required: true, message: 'Nhập email của bạn' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Nhập mật khẩu' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Lưu mật khẩu</Checkbox>
              </Form.Item>

              <Link onClick={() => history.push('/forgotpassword')} style={{ float: 'right' }}>Quên mật khẩu?</Link>
            </Form.Item>
            <Space direction="vertical">
              { error && (
                <Text type="danger">
                  { errorMsg }
                </Text>
              ) }
            </Space>
            <Form.Item>
              <Row>
                <Col span={14} style={{paddingTop: '8px'}}>
                  <span style={{ paddingRight: '8px' }}>Bạn không có tài khoản?</span>
                  <Link onClick={() => history.push('/register')}>Đăng ký</Link>
                </Col>
                <Col span={10} style={{ textAlign: 'right' }}>
                  <Button key="confirmButton" form="login" type='primary' size='large' htmlType="submit">Đang nhập</Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
};
