import { RootState } from '../hooks';

export const getCompanyName = (state: RootState) => state.organization.info.companyName;
export const getBillingAddress = (state: RootState) => state.organization.info.billingAddress;
export const getBillingAddress2 = (state: RootState) => state.organization.info.billingAddress2;
export const getOrgCity = (state: RootState) => state.organization.info.billingCity;
export const getOrgState = (state: RootState) => state.organization.info.billingState;
export const getOrgZipCode = (state: RootState) => state.organization.info.billingZip;
export const getOrgCrmId = (state: RootState) => state.organization.info.crmId;
export const getOrgDuns = (state: RootState) => state.organization.info.duns;
export const getOrgNaics = (state: RootState) => state.organization.info.naics;
export const getOrgGuid = (state: RootState) => state.organization.info.guid;
export const getOrg = (state: RootState) => state.organization;
export const getOrgUserCurrentPage = (state: RootState) => state.organization.users.currentPage;
export const getUserFilterState = (state: RootState) => state.organization.users.filter;
export const getOrgLicense = (state: RootState) => state.organization.license;
export const getIsEditMode = (state: RootState) => state.organization.isEditMode;
