import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GlobalReducerStore {
  pagination: {
    pageSize: number;
  };
}

const initialState: GlobalReducerStore = {
  pagination: {
    pageSize: 10,
  },
};

export const GlobalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
  },
});

export const GlobalActions = GlobalSlice.actions;
export const GlobalReducer = GlobalSlice.reducer;
