import React, { useEffect } from 'react';
import { Layout } from 'antd';
import {
  Redirect, Route, Switch, useHistory, useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Routes } from './Routes';
import { UserSelectors } from '~/Store/User';
import { WaitOverlayActions } from '~/Store/Overlays';
import { useAppDispatch } from '~/Store/hooks';
import { Page, PrivateRoute, WaitOverlay } from '~/Components';
import { injectHistory, refreshAccessToken } from '~/Services/AuthIntercept';
import { LoginWorkFlow } from '~/Components/LoginWorkFlow';

import styles from './Main.module.less';

const { Content } = Layout;

export const Main = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const waitOverlayName = 'loginOverlay';
  const authenticated = useSelector(UserSelectors.isAuthenticated);
  
  const refreshLogin = async () => {
    if (authenticated) {
      return;
    }
    dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: true }));
    const currentLocation = { ...location };
    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) {
      dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: false }));
      history.push('/login', { from: location });
      return;
    }
    try {
      await refreshAccessToken(refreshToken);
      dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: false }));
      history.replace(currentLocation);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    injectHistory(history);
    refreshLogin();
  }, []);

  return (
    <WaitOverlay name={waitOverlayName} size="large" wrapperClassName={styles.FullOverlay}>
      <LoginWorkFlow>
        {authenticated && (
          <Layout>
            <Content>
              <Switch>
                <Route exact path="/" render={() => (<Page title="Home"><div>home</div></Page>)} />
                {Routes
                  .map(({
                    label,
                    rootPermission,
                    orgPermission,
                    routeProps,
                  }) => (
                    <PrivateRoute
                      allowedRoles={[...rootPermission, ...orgPermission]}
                      key={`${label}`}
                      {...routeProps}
                    />
                  ))}
                <Redirect to="/" />
              </Switch>
            </Content>
          </Layout>
        )}
      </LoginWorkFlow>
    </WaitOverlay>
  );
};
