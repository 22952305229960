import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserFilterState, UserItemData } from '~/Services/UserService.d';

export interface OrganizationReducerStore {
  info: OrganizationInfo,
  users: {
    currentPage: number,
    filter: UserFilterState
  },
  license: LicenseData[],
  isEditMode: boolean,
}

export interface LicenseData {
  activeDate: string,
  expiredate: string,
  guid: string,
  licenseType: LicenseTypeData,
  licenseTypeId: string,
  organizationId: string,
  state?: string,
  userGuid?: string[]
  user: UserItemData[]
}
export interface LicenseTypeData {
  guid: string,
  name: string,
  offlineRange: number,
  product?: string,
  productID: string,
  type: string
}

export interface OrganizationInfo {
  companyName: string,
  billingAddress: string,
  billingAddress2: string,
  billingCity: string,
  billingState: string,
  billingZip: string,
  crmId: string,
  duns: string,
  naics: string,
  guid: string
}

const initInfo: OrganizationInfo = {
  companyName: '',
  billingAddress: '',
  billingAddress2: '',
  billingCity: '',
  billingState: '',
  billingZip: '',
  crmId: '',
  duns: '',
  naics: '',
  guid: '',
};

const initialState: OrganizationReducerStore = {
  info: initInfo,
  users: {
    currentPage: 1,
    filter: { status: '', userType: [], criteria: '' },
  },
  license: [],
  isEditMode: false,
};

export const OrganizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setCompanyName: (state, action: PayloadAction<string>) => {
      state.info.companyName = action.payload;
    },
    setBillingAddress: (state, action: PayloadAction<string>) => {
      state.info.billingAddress = action.payload;
    },
    setBillingAddress2: (state, action: PayloadAction<string>) => {
      state.info.billingAddress2 = action.payload;
    },
    setBillingCity: (state, action: PayloadAction<string>) => {
      state.info.billingCity = action.payload;
    },
    setBillingState: (state, action: PayloadAction<string>) => {
      state.info.billingState = action.payload;
    },
    setBillingZip: (state, action: PayloadAction<string>) => {
      state.info.billingZip = action.payload;
    },
    setDuns: (state, action: PayloadAction<string>) => {
      state.info.duns = action.payload;
    },
    setNaics: (state, action: PayloadAction<string>) => {
      state.info.naics = action.payload;
    },
    setOrganization: (state, action: PayloadAction<OrganizationInfo>) => {
      state.info = action.payload;
    },
    setUsersCurrentPage: (state, action) => {
      state.users.currentPage = action.payload;
    },
    setUserFilterState: (state, action) => {
      state.users.filter = action.payload;
      state.users.currentPage = 1;
    },
    setOrgLicense: (state, action) => {
      state.license = action.payload;
    },
    toggleIsEditMode: (state) => {
      state.isEditMode = !state.isEditMode;
    },
    setIsEditMode: (state, action: PayloadAction<boolean>) => {
      state.isEditMode = action.payload;
    },
  },
});

export const OrganizationActions = OrganizationSlice.actions;
export const OrganizationReducer = OrganizationSlice.reducer;
