import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Alert,
  Button, Col,
  Form,
  Input,
  Layout,
  Row,
  Typography,
} from 'antd';
import { CxvLogoSvg } from '~/Components';
import { UserService } from '~/Services';
import { Utils } from '~/Utils';
import { UserActions } from '~/Store/User';
import { useDispatch } from 'react-redux';
// import style from './PasswordResetView.module.less';

const { Title } = Typography;
const { Content } = Layout;

export const PasswordResetHicas = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const validationRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!$#@&%~])([a-zA-Z0-9!$#@&%~]{8,})$/;
  const [disabled, setIsDisabled] = useState(true);
  const [error, setHasError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [inprogress, setProgress] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const setDisabled = () => {
    const { password, confirm } = form.getFieldsValue();
    const errors = form.getFieldsError();
    const fieldsWithErrors = errors.find((o) => o.errors.length > 0);
    setIsDisabled(fieldsWithErrors !== undefined || !(password !== undefined && confirm !== undefined));
  };
  const validateMatch = async (rule: any, value: string) => {
    const pwd = form.getFieldValue('password');
    if (pwd !== value) {
      throw new Error('Mật khẩu không khớp');
    }
  };
  const onSubmit = async () => {
    const { password } = form.getFieldsValue();
    const params = new URLSearchParams(location.search);
    const cb = params.get('cb');
    try {
      setProgress(true);
      await UserService.Put.forgotPassword(cb as string, password);
      setSuccess(true);
      form.resetFields();
      // we will redirect you in 10 seconds
      setProgress(false);
      dispatch(UserActions.logout());
      await Utils.delay(10000);
      history.push('/home');
    } catch (err) {
      setProgress(false);
      setHasError(true);
      form.resetFields();
    }
    // updatePassword
  };

  return (
    <Layout style={{ height: '100vh' }}>

      <Content style={{ margin: 24 }}>
        <Row>
          <Col span={12} offset={6}>

            <Row>
              <CxvLogoSvg width={200} />
            </Row>

            <Row align="middle" justify="center">
              <Title level={3}>Đặt lại mật khẩu của bạn ở bên dưới</Title>
            </Row>
            <Row gutter={48} justify="space-around" align="middle">
              <Form
                layout="vertical"
                style={{ width: 350 }}
                form={form}
                requiredMark="optional"
                onFieldsChange={setDisabled}
                onFinish={onSubmit}
                onFinishFailed={() => console.log('failed')}
              >
                <Form.Item
                  label="Mật khẩu"
                  name="password"
                  rules={[
                    { required: true, message: 'Nhập mật khẩu của bạn!' },
                    {
                      pattern: validationRegex,
                      message: 'Mật khẩu của bạn phải dài ít nhất 8 ký tự, bắt buộc phải có một chữ cái viết hoa, một chữ cái viết thường và một ký tự đặc biệt (! $ # @ &% ~).',
                    },
                  ]}
                >
                  <Input.Password disabled={error || success} />
                </Form.Item>
                <Form.Item
                  label="Xác nhận lại mật khẩu"
                  name="confirm"
                  required
                  rules={[
                    { required: true, message: 'Nhập mật khẩu của bạn!' },
                    { validator: validateMatch },
                  ]}
                >
                  <Input.Password disabled={error || success} />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={disabled || error || success}
                    loading={inprogress}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Row>
            <Row align="middle" justify="center">

              {error && (
                <Alert
                  type="error"
                  message="Dường như có lỗi từ máy chủ. Làm ơn đóng ứng dụng và liên hệ với quản trị viên"
                />
              )}
              {success && (
                <Alert
                  type="success"
                  message="Mật khẩu của bạn đã được thay đổi thành công."
                />
              )}

            </Row>
          </Col>
        </Row>
      </Content>

    </Layout>
  );
};
