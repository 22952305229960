import { EditOutlined } from '@ant-design/icons';
import {
  Avatar,
  Col,
  Divider,
  Image,
  List,
  Row,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { WaitOverlay } from '~/Components';
import { ScrollBar } from '~/Components/ScrollBar';
import { UserService } from '~/Services';
import { OrganizationsService } from '~/Services/OrganizationService';
import { UserData } from '~/Services/UserService.d';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { OrganizationActions } from '~/Store/Organization';
import { WaitOverlayActions } from '~/Store/Overlays';
import { UserActions, UserSelectors } from '~/Store/User';
import { Utils } from '~/Utils';
import { OrgClaim, RootClaim } from '../../Routes';
import styles from './Info.module.less';

const { Title } = Typography;

const waitOverlayName = 'fetchingAdminInfo';

export const BodyInfo = () => {
  const dispatch = useAppDispatch();
  const organization = useAppSelector(UserSelectors.getDefaultOrganization);
  const currentUser = useAppSelector(UserSelectors.getCurrentUser);
  const userRoles = useAppSelector(UserSelectors.getRoles);
  const orgRules = useAppSelector(UserSelectors.getOrgRoles);

  const userCanEdit = userRoles.includes(RootClaim.System) || orgRules.includes(OrgClaim.Superuser);

  const [users, setUsers] = useState<UserData[]>([]);

  const fetchOrganizationUsers = async () => {
    if (!organization) return;
    try {
      dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: true }));
      const res: any = await OrganizationsService.Get.getOrganizationUsers(
        organization.guid,
        {
          paging: false
        },
        [OrgClaim.Superuser],
        ['Accepted']
      );
      const { data } = res;
      setUsers(data);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: false }));
    }
  };

  const getUserProfile = async () => {
    if (currentUser) return;
    try {
      const req = await UserService.Get.fetchCurrentUser();
      const { data } = req;
      dispatch(UserActions.setCurrentUser(data));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchOrganizationUsers();
  }, []);

  useEffect(() => {
    getUserProfile();
  }, [currentUser]);

  const {
    phoneNumber, email,
  } = currentUser || {};

  const {
    companyName, billingAddress, billingAddress2,
    billingCity, billingState, billingZip,
  } = organization || {};

  const onClickEdit = () => {
    dispatch(OrganizationActions.toggleIsEditMode());
  };

  return (
    <WaitOverlay
      name={waitOverlayName}
      size="default"
      wrapperClassName={styles.FullOverlay}
    >
      <div>
        <Row align="stretch" className={styles.editRow}>
          <Title style={{ flex: 1 }} level={4}>Organization Information</Title>
          {userCanEdit
            ? (
              <div
                className={styles.editOrg}
                onClick={onClickEdit}
              >
                <div className={styles.editWord}>
                  Edit organization
                </div>
                <EditOutlined />
              </div>
            ) : null}
        </Row>
        <ScrollBar>
          <div className={styles.organizationInfo}>
            <Row>
              <Col>
                <Image
                  src=""
                  width={200}
                  fallback="/assets/images/building-line.png"
                />
              </Col>
              <Col>
                <List size="small" grid={{ gutter: 8, column: 1 }}>
                  <List.Item>
                    <Title level={5}>
                      {companyName}
                    </Title>
                  </List.Item>
                  <List.Item>{email}</List.Item>
                  <List.Item>Job Title</List.Item>
                  <List.Item>{phoneNumber}</List.Item>
                </List>
              </Col>
            </Row>
            <Row>
              <Col>
                <List size="small" grid={{ gutter: 8, column: 1 }}>
                  <List.Item>
                    <Title level={5}>
                      Address
                    </Title>
                  </List.Item>
                  <List.Item>Address 1</List.Item>
                  <List.Item>Address 2</List.Item>
                </List>
              </Col>
            </Row>
            <Row>
              <Col>
                <List size="small" grid={{ gutter: 8, column: 1 }}>
                  <List.Item>
                    <Title level={5}>
                      Billing Address
                    </Title>
                  </List.Item>
                  <List.Item>
                    {[
                      billingAddress,
                      billingCity,
                      billingState,
                      billingZip,
                    ]
                      .filter((x) => x && x.trim().length > 0)
                      .join(', ')}
                  </List.Item>
                  <List.Item>
                    {[
                      billingAddress2,
                      billingCity,
                      billingState,
                      billingZip,
                    ]
                      .filter((x) => x && x.trim().length > 0)
                      .join(', ')}
                  </List.Item>
                </List>
              </Col>
            </Row>
            <Divider />
            <Row align="stretch" className={styles.adminsHeader}>
              <Title style={{ flex: 1 }} level={4}>Admins</Title>
            </Row>
            <Row gutter={[10, 10]} style={{ marginLeft: '0px', marginRight: '0px' }}>
              {
                users.map((user) => (
                  <Col sm={24} md={12} key={user.id}>
                    <Row className={styles.adminItem} align="middle">
                      <Avatar
                        src={user.avatarUrl}
                        className={styles.orgLogo}
                        style={{
                          backgroundColor: user.avatarUrl
                            ? undefined
                            : Utils.stringToColour(user.firstname || user.email),
                        }}
                      >
                        {user.firstname
                          ? user.firstname.charAt(0).toLocaleUpperCase()
                          : user.email.charAt(0).toLocaleUpperCase()}
                      </Avatar>
                      <Title level={5}>{user.userName}</Title>
                    </Row>
                  </Col>
                ))
              }
            </Row>
          </div>
        </ScrollBar>
      </div>
    </WaitOverlay>
  );
};
