import React from 'react';
import { Layout } from 'antd';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PageHeader } from '~/Components/PageHeader';
import { Login, ForgotPassword, Register } from '../Login';
import { Main } from '../Main';
import { RegisterConfirmation } from '../Login/Register/RegisterConfirmation';
import { LeftMenu } from '~/Views/Main/LeftMenu';
import { UserSelectors } from '~/Store/User';

export const Root = () => {
  const authenticated = useSelector(UserSelectors.isAuthenticated);
  return (
    <Layout style={{ height: '100vh' }}>
      {authenticated && <LeftMenu />}
      <Layout>
        <PageHeader />
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/registerconfirmation" component={RegisterConfirmation} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route path="/" component={Main} />
        </Switch>
      </Layout>
    </Layout>
  );
};
