/* eslint-disable @typescript-eslint/indent */
import React, { FC, useState } from 'react';
import {
  Row,
  Col,
  Tag,
  Avatar,
  Spin,
} from 'antd';

import { OrgClaim } from '../../Routes';
import { UserItemData } from '~/Services/UserService.d';
import styles from './Users.module.less';
import { DropOption } from '~/Components/DropOption';
import { Utils } from '~/Utils';

export const tagColor = (text: string) => {
  let color = 'blue';
  switch (text) {
  case OrgClaim.Member:
    color = 'green';
    break;
  case OrgClaim.Superuser:
    color = 'blue';
    break;
  case 'Declined':
  case OrgClaim.Admin:
    color = 'red';
    break;
  case 'Accepted':
    color = 'blue';
    break;
  case 'Pending':
    color = 'orange';
    break;
  default:
    color = Utils.stringToColour(text);
    break;
  }
  return color;
};
interface UserItemProps {
  user: UserItemData;
  hasAdmin: boolean;
  onClick: () => void;
  changeUserStatus: (email: string, status: string) => Promise<void>;
}

export const UserItem: FC<UserItemProps> = ({ user, onClick, changeUserStatus, hasAdmin }) => {
  const [changing, setChange] = useState(false);

  const handelChangeUserStatus = async (email: string, event: any) => {
    const { key: status } = event;
    setChange(true);
    await changeUserStatus(email, status);
    setChange(false);
  };

  return (
    <div key={user.id} className={styles.UserItem}>
      <Row gutter={[8, 8]}>
        <Col
          lg={24}
          xl={1}
          onClick={onClick}
          style={{ cursor: 'pointer' }}
          data-id={user.id}
        >
          <Avatar
            src={user.avatarUrl && !user.avatarUrl.startsWith('http') ? `https://${user.avatarUrl}` : undefined}
            style={{
              backgroundColor: user.avatarUrl
                ? undefined
                : Utils.stringToColour(user.id),
            }}
          >
            {user.firstname
              ? user.firstname.charAt(0).toLocaleUpperCase()
              : user.email.charAt(0).toLocaleUpperCase()}
          </Avatar>
        </Col>
        <Col
          xs={24}
          lg={12}
          xl={7}
          onClick={onClick}
          style={{ cursor: 'pointer' }}
          data-id={user.id}
        >
          <div className={styles.AlignMiddle}>
            {`${user.firstname || ''} ${user.lastname || ''}`}
          </div>
        </Col>
        <Col
          xs={24}
          lg={12}
          xl={6}
          onClick={onClick}
          style={{ cursor: 'pointer' }}
          data-id={user.id}
        >
          <div className={styles.AlignMiddle}>{user.email}</div>
        </Col>
        <Col xs={24} lg={12} xl={6}>
          <div className={styles.AlignMiddle}>
            {user.organizationRoles.map((tag) => (
              <Tag key={tag} color={tagColor(tag)}>
                {tag}
              </Tag>
            ))}
          </div>
        </Col>
        <Col xs={24} md={12} lg={12} xl={4}>
          {user.invitationStatus && (
            <div className={styles.AlignMiddle}>
              {hasAdmin ? (
                <Spin size="small" spinning={changing}>
                  <DropOption
                    title={user.invitationStatus}
                    color={tagColor(user.invitationStatus)}
                    menuOptions={[
                      { key: 'Pending', name: 'Pending' },
                      { key: 'Declined', name: 'Declined' },
                    ]}
                    buttonStyle={{ marginLeft: '0px', paddingLeft: '0px' }}
                    onMenuClick={(evt) =>
                      handelChangeUserStatus(user.email, evt)
                    }
                  />
                </Spin>
              ) : (
                <Tag color={tagColor(user.invitationStatus)}>
                  {user.invitationStatus}
                </Tag>
              )}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};
