import {
  AxiosRequestConfig, AxiosResponse, AxiosError, AxiosPromise,
} from 'axios';
import authIntercept from './AuthIntercept';

export const Get = (url: string, options?: AxiosRequestConfig): AxiosPromise<AxiosResponse | AxiosError> => {
  const defaultOptions = {
    method: 'GET' as const,
    url,
    timeout: 30000,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    ...options,
  };
  return authIntercept(defaultOptions);
};
